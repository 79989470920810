import ApiResource from "@/includes/resource";

const resourceName = 'categories'

class CategoriesResource extends ApiResource {
    

    constructor() {
        super(resourceName)
    }

    me() {
        return this.get('/api/user')
    }

    async login(credentials) {
        return await this.post('/login', credentials)
    }

    async logout() {
        return await this.post('/logout')
    }

    async duplicate(id) {
        return await this.post(`/api/${resourceName}/${id}/duplicate`)
    }
}

export default new CategoriesResource()