window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
}

const apiClient = axios.create(
    {
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
        timeout: 60000 * 5,
        headers: headers,
    }
)

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

apiClient.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        // console.log(error.response.data)
        // let message = error.message;
        //
        // console.log(error.response)
        //
        // if (error.response.data && error.response.data.errors) {
        //     message = error.response.data.errors;
        // } else if (error.response.data && error.response.data.error) {
        //     message = error.response.data.error;
        // }
        //
        // if (message === 'Request failed with status code 422') {
        //     // alert('asdas')
        // }
        //
        // if (message === 'Request failed with status code 401') {
        //     message = 'Нет доступа, пожалуйста авторизуйтесь <br><b><a href=\'/login\'>Перейти</a></b>';
        //     console.log(message)
        // } else {
        //     console.log(message)
        // }
        // throw error.response
        
        return error.response;
    }
);

export default apiClient;
