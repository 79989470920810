<template>
  <v-snackbar
    v-model="model"
    :color="color"
    :timeout="timeout">
    {{ message }}
  </v-snackbar>
</template>

<script>
  export default {
    name: 'Notify',
    props: ['message', 'color'],
    data() {
      return {
        model: true,
        timeout: 4000,
      }
    },
    mounted() {
      setTimeout(() => {
        this.$emit('timeout')
      }, this.timeout)
    }
  }
</script>