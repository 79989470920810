<template>
  <v-overlay :value="true">
    <v-scale-transition appear origin="center center">
      <v-card color="white" width="300">
        <v-card-text class="text-h5 text-center black--text">
          <v-spacer></v-spacer>
          <p class="text-wrap">{{ message }}</p>
          <v-spacer></v-spacer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" outlined text @click="onCancel()">Отменить</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined text @click="onConfirm()">{{ buttonLabel }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
  </v-scale-transition>
  </v-overlay>
</template>

<script>
export default {
  name: 'DialogConfirm',
  props: ['message', 'buttonLabel'],
  data () {
    return {
    }
  },
  methods: {
    onConfirm () {
      this.$emit('confirm')
      this.dialog = false
    },
    onCancel () {
      this.$emit('cancel')
      this.dialog = false
    },
  }
}
</script>