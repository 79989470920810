import Vue from 'vue'
import Dialog from './dialog-confirm.vue'

export default {
  dialog: undefined,
  mountEl: undefined,

  show({ message, buttonLabel = 'Подтвердить' }, onConfirm, onCancel = () => {}) {
    const DialogClass = Vue.extend(Dialog);
      this.dialog = new DialogClass({
      propsData: { message, buttonLabel },
    });

    this.dialog.$on('confirm', () => { this.dispose(); onConfirm() });
    this.dialog.$on('cancel', () => { this.dispose(); onCancel() });

    const parent = document.getElementById('app')
    this.mountEl = document.createElement('div');
    parent.appendChild(this.mountEl);
    this.dialog.$mount(this.mountEl);
  },
  dispose () {
    this.mountEl.remove()
    this.mountEl = null

    this.dialog.$destroy();
    this.dialog.$el.remove();
    this.dialog = null;
  }
}