import Vue from 'vue'
import Notify from './notify.vue'

let notify, mountEl

// TODO: (надо ли?) доработать возможность открытия несокльких снакбаров
// и уничтожать их последовательно в порядкке появления (таймера)
const dispose = () => {
  if (mountEl) {
    mountEl.remove()
    mountEl = null
  }

  if (notify) {
    notify.$destroy()
    notify.$el.remove()
    notify = null
  }
}

export default {
  show({ message, color = 'success'}) {
    dispose()

    const NotifyClass = Vue.extend(Notify)
    notify = new NotifyClass({
      propsData: { message, color },
    })

    notify.$on('timeout', dispose)

    const parent = document.getElementById('app')
    mountEl = document.createElement('div')
    parent.appendChild(mountEl)
    notify.$mount(mountEl)
  },
}