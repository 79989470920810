import ApiResource from "@/includes/resource";

class CashReportsResource extends ApiResource {
    constructor() {
        super('pizzerias')
    }

    async cities() {
        return await this.get('/api/pizzerias/cities')
    }

}

export default new CashReportsResource()