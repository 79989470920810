import Vue from "vue"
import App from "./App"

import router from './includes/router'
import store from './includes/store'
import vuetify from './includes/vuetify'

// import './includes/quasar'

import mobileMixin from './mixins/mobileMixin'

import './includes/editor'
import './includes/viewer'

import VueApexCharts from 'vue-apexcharts'
Vue.component('apexchart', VueApexCharts)

Vue.mixin(mobileMixin)

const vm = new Vue({
    router,
    store,
    vuetify,
        render: h => h(App),
    el: '#app',
})



export { vm }
