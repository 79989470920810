import request from './request'

class ApiResource {
    constructor(url) {
        this.url = url
    }

    get(url, params = {}) {
        return request({
            url: url,
            method: 'get',
            params: params,
        });
    }

    async post(url, params = {}) {
        if (url.indexOf('api') == -1) {
            await request(
                {
                    url: '/sanctum/csrf-cookie',
                    method: 'get',
                }
            )
        }
        return request({
            url: url,
            method: 'post',
            data: params,
        });
    }

    async put(url, params = {}) {
        if (url.indexOf('api') == -1) {
            await request(
                {
                    url: '/sanctum/csrf-cookie',
                    method: 'get',
                }
            )
        }
        return request({
            url: url,
            method: 'put',
            params: params,
        });
    }

    async destroy(url) {
        if (url.indexOf('api') == -1) {
            await request(
                {
                    url: '/sanctum/csrf-cookie',
                    method: 'get',
                }
            )
        }
        return request({
            url: url,
            method: 'delete',
        });
    }

    list(query) {
        return this.get('/api/' + this.url, query)
    }

    item(id) {
        return this.get('/api/' + this.url + '/' + id)
    }

    store(resource) {
        return this.post('/api/' + this.url, resource)
    }

    update(id, resource) {
        return this.put('/api/' + this.url + '/' + id, resource)
    }

    delete(id) {
        return request({
            url: '/api/' + this.url + '/' + id,
            method: 'delete',
        })
    }
}

export {ApiResource as default}
